import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { customElementComponentType, TOKEN } from '../../consts';

export async function updateInstanceId({
  editorSDK,
  appData,
  lastConsentedOriginId,
}: {
  editorSDK: FlowEditorSDK;
  appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  };
  lastConsentedOriginId: string;
}): Promise<void> {
  const { instanceId } = appData;

  const customElementComponentRefs =
    await editorSDK.document.components.findAllByType(TOKEN, {
      componentType: customElementComponentType,
    });

  for (const customElementComponentRef of customElementComponentRefs) {
    const componentData = await editorSDK.components.data.get(TOKEN, {
      componentRef: customElementComponentRef,
    });

    // @ts-ignore
    const initialAttributes = JSON.parse(componentData.initialAttributes);

    const wixConfig = JSON.parse(initialAttributes.wixconfig);

    const newWixConfig = {
      ...wixConfig,
      instanceId,
      originInstanceId: lastConsentedOriginId,
    };

    const newInitialAttributes = {
      ...initialAttributes,
      wixconfig: JSON.stringify(newWixConfig),
    };

    // @ts-ignore
    componentData.initialAttributes = JSON.stringify(newInitialAttributes);

    // @ts-ignore
    const url = new URL(componentData.url);
    url.searchParams.delete('instanceId');
    url.searchParams.append('instanceId', instanceId);

    // @ts-ignore
    componentData.url = url.toString();

    editorSDK.components.data.update(TOKEN, {
      componentRef: customElementComponentRef,
      data: componentData,
    });
  }
}
