import { DeviceType, EventType } from '@wix/platform-editor-sdk';
import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { addMyBusinessPanelConfigurationToHotels } from './app-specific-logic/hotels/hotels-my-business-panel-config';
import {
  HOTEL_RUNNER_APP_ID,
  STAGING_HOTELS_RUNNER_APP_ID,
} from './app-specific-logic/hotels/hotels.constants';
import { configureWidgetManifest } from './components/webComponent/manifest';
import { customElementComponentType, TOKEN } from './consts';
import { firstInstallFlow } from './lib/installation-flows/first-install-flow';
import {
  getLastConsentedOrigin,
  setFirstTimeInstanceHistory,
} from './lib/lifecycle-flows/instance-history';
import { updateComponentsData } from './lib/lifecycle-flows/update-component';
import { updateInstanceId } from './lib/lifecycle-flows/update-instance-id';
import { updateViewMode } from './lib/lifecycle-flows/update-view-mode';
import { openDashboard } from './lib/panel-flows/dashboard';
import {
  openSettingsPanel,
  reportBiEventGfppSettingsClicked,
  saveSettingsToThirdPartyService,
} from './lib/panel-flows/settings';
import { AppData, IWebComponent } from './types/web-component';
import {
  deleteMetaSiteCacheData,
  getMetaSiteCacheData,
} from './utils/metasite.utils';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  editorOptions,
  flowApi
) => {
  const {
    essentials: { httpClient },
  } = flowApi;
  const { firstInstall } = editorOptions;

  const appData: AppData = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId
  );
  const isHotels =
    appData.appDefinitionId === HOTEL_RUNNER_APP_ID ||
    appData.appDefinitionId === STAGING_HOTELS_RUNNER_APP_ID;

  const { originInstanceId, instanceId, demoMode } =
    await editorSDK.document.info.getAppInstancePayload(TOKEN);

  console.log('demoMode', demoMode);

  if (firstInstall && isHotels) {
    setFirstTimeInstanceHistory({ httpClient, instanceId });
  }

  const isDuplicatedSite = Boolean(originInstanceId);
  try {
    if (isDuplicatedSite && isHotels) {
      // Find the last consented instance of the origin ID
      const lastConsentedOriginId = await getLastConsentedOrigin({
        httpClient,
        originInstanceId,
        instanceId,
      });

      // update the componentData url with the current instance and set the originID to be the last consented instance
      await updateInstanceId({
        editorSDK,
        appData,
        lastConsentedOriginId: lastConsentedOriginId ?? originInstanceId,
      });
      // set the current instance with the last consented ID
      setFirstTimeInstanceHistory({
        httpClient,
        instanceId,
        lastConsentedOriginId,
        originInstanceId,
      });
    } else if (isDuplicatedSite) {
      await updateInstanceId({
        editorSDK,
        appData,
        lastConsentedOriginId: originInstanceId,
      });
    }
  } catch (error) {
    console.error('error updating instance id for duplicate site flow ', {
      error,
    });
  }

  const metaSiteData = await getMetaSiteCacheData(editorSDK);

  // update the instance-id with the current instance-id in order to
  // solve a bug when duplicating a site also duplicates the instance-id
  if (metaSiteData) {
    await deleteMetaSiteCacheData(editorSDK);
  }

  const webComponents: IWebComponent[] =
    appData?.components?.filter((comp: any) => comp?.type === 'WEB') ?? [];

  const pages = await editorSDK.pages.data.getAll('');
  const hasHotelRunnerPage = pages.some(
    (page) => page.managingAppDefId === HOTEL_RUNNER_APP_ID
  );

  const hotelRunnerFirstInstall = isHotels && !hasHotelRunnerPage;

  await editorSDK.document.application.reloadManifest();

  //  first install is broken from the editor, it will always be true. I am leaving this logic for now, but fixing it for hotels to be based on if the essential comp is installed or not.
  if ((!isHotels && firstInstall) || hotelRunnerFirstInstall) {
    firstInstallFlow({
      editorSDK,
      webComponents,
      metaSiteData,
      flowApi,
      appData,
      appDefinitionId,
    });
  }

  const customElementComps = await editorSDK.document.components.findAllByType(
    TOKEN,
    {
      componentType: customElementComponentType,
    }
  );

  // update changes of custom element components (scriptTag)
  updateComponentsData({
    editorSDK,
    instanceId: appData.instanceId,
    webComponents,
    components: customElementComps,
  });

  editorSDK.addEventListener(EventType.widgetGfppClicked, async (event) => {
    const { detail } = event;

    if (detail.id === 'dashboard') {
      openDashboard(editorSDK);
    }

    if (detail.id === 'connect') {
      openSettingsPanel(editorSDK, detail.componentRef);
      reportBiEventGfppSettingsClicked(
        flowApi,
        editorSDK,
        event,
        webComponents
      );
    }
  });

  editorSDK.addEventListener(EventType.switchedToMobileView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      TOKEN,
      {
        componentType: customElementComponentType,
      }
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        mode: DeviceType.Mobile,
        compRef,
        appDefinitionId,
      });
    }
  });

  editorSDK.addEventListener(EventType.switchedToDesktopView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      TOKEN,
      {
        componentType: customElementComponentType,
      }
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        mode: DeviceType.Desktop,
        compRef,
        appDefinitionId,
      });
    }
  });

  editorSDK.addEventListener(EventType.siteWasPublished, async () => {
    for (const component of webComponents) {
      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToThirdPartyService({
          appId: appDefinitionId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            componentId: component.componentId,
            instanceId: appData.instanceId,
            status: 'published',
          },
        });
    }
  });

  editorSDK.addEventListener(EventType.themeChanged, async (e) => {
    //@ts-ignore
    if (e?.detail?.changeType === 'STYLE') return;

    const [colors, fonts] = await Promise.all([
      editorSDK.document.theme.colors.getAll(TOKEN),
      editorSDK.document.theme.fonts.getMap(TOKEN),
    ]);

    for (const component of webComponents) {
      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToThirdPartyService({
          appId: appDefinitionId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            data: {},
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
    }

    const componentsByType = await editorSDK.document.components.findAllByType(
      TOKEN,
      {
        componentType: customElementComponentType,
      }
    );

    for await (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        compRef,
        appDefinitionId,
        reset: true,
      });
    }
  });

  editorSDK.addEventListener(
    EventType.anyComponentAddedToStage,
    async (data) => {
      const fullData: any = await editorSDK.components.data.get(TOKEN, {
        componentRef: data.detail.compRef,
      });
      // controllerType is the component Id
      const { applicationId, controllerType } = fullData;

      const appDataToUpdate: any =
        await editorSDK.document.tpa.app.getDataByAppDefId(
          applicationId,
          applicationId
        );
      const component: any = appDataToUpdate.components?.find(
        (comp: any) => comp.componentId === controllerType
      );

      if (component.data?.gfppSettings?.fetchInitialData) {
        const [colors, fonts] = await Promise.all([
          editorSDK.document.theme.colors.getAll(TOKEN),
          editorSDK.document.theme.fonts.getMap(TOKEN),
        ]);

        saveSettingsToThirdPartyService({
          appId: applicationId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
      }
    }
  );
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  { initialAppData }
) => {
  const { appDefinitionId } = initialAppData;
  await configureWidgetManifest(appManifestBuilder, editorSDK, appDefinitionId);

  if (appDefinitionId === HOTEL_RUNNER_APP_ID) {
    addMyBusinessPanelConfigurationToHotels({
      appManifestBuilder,
      editorSDK,
      initialAppData,
    });
  }

  // configure page options
  return appManifestBuilder
    .configurePages((pagesBuilder) => {
      // page settings
      pagesBuilder
        .addSettingsTab(
          {
            title: 'Page Info',
            helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
            type: 'page_info',
          },
          {
            title: 'Layout',
            type: 'layout',
          },
          {
            title: 'Permissions',
            type: 'permissions',
          },
          {
            title: 'SEO',
            helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
            type: 'seo',
          }
        )
        // page actions
        .addAction(
          {
            type: 'page_rename',
          },
          {
            type: 'page_delete',
            onClick: async () => {
              await editorSDK.application.uninstall(TOKEN, {
                openConfirmation: true,
              });
            },
          }
        );
    })
    .build();
};
