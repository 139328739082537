import { gfppOriginStrings } from '@wix/platform-editor-sdk';
import { ComponentRef, FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { APESTER_APP_ID } from '../../app-specific-logic/app-ids';
import { IWebComponent } from '../../types/web-component';
import { appSettingsClickOnSettingsButton } from '@wix/bi-logger-custom-elements/v2';
import { TOKEN } from '../../consts';
import webComponent from '../../components/webComponent/.component.json';

export const panelUrlBuilder = ({
  editorSDK,
  componentRef,
  isApester,
  compId,
  version,
  metaSiteId,
  tagName,
}: {
  editorSDK: FlowEditorSDK;
  componentRef: ComponentRef;
  isApester: boolean;
  compId?: string;
  version: string;
  metaSiteId: string;
  tagName: string;
}) => {
  const apesterBaseURl = `https://editor.wixapps.net/render/prod/settings/web-component-wrapper/${process.env.ARTIFACT_VERSION}/${webComponent.id}`;

  const baseUrl = isApester
    ? apesterBaseURl
    : `https://www.wix.com/_serverless/dynamic-settings-renderer`;

  return `${baseUrl}?wix-sdk-version=${getEditorSdkSource(
    editorSDK
  )}&componentId=${
    componentRef.id
  }&compId=${compId}&version=${version}&metaSiteId=${metaSiteId}&tagName=${tagName}`;
};

export async function openSettingsPanel(
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef
) {
  const appId = await editorSDK.document.info.getAppDefinitionId(TOKEN);
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  } = await editorSDK.document.tpa.app.getDataByAppDefId(appId, appId);

  const controllerData = await editorSDK.document.controllers.getData(TOKEN, {
    controllerRef: componentRef,
  });

  const webComponent: IWebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string; componentId: string }) =>
          comp.type === 'WEB' &&
          comp.componentId === controllerData?.config?.componentId
      )) ||
    {};

  // get application data to set the title, height, width
  editorSDK.editor.openComponentPanel(TOKEN, {
    title: webComponent?.data?.modalTitle ?? 'Settings',
    url: panelUrlBuilder({
      editorSDK,
      componentRef,
      isApester: appData?.appDefinitionId === APESTER_APP_ID,
      compId: webComponent.componentId,
      version: appData.version as string,
      metaSiteId: await editorSDK.info.getMetaSiteId(TOKEN),
      tagName: (await getCustomElementTagName(editorSDK, componentRef, [
        webComponent,
      ])) as string,
    }),
    height: 582,
    width: 402,
    componentRef,
    helpId: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
  });
}

export const reportBiEventGfppSettingsClicked = async (
  flowAPI: FlowAPI,
  editorSDK: FlowEditorSDK,
  widgetGfppClickedEvent: CustomEvent<{
    id: string;
    componentRef: ComponentRef;
    gfppOrigin: gfppOriginStrings;
  }>,
  webComponents: IWebComponent[]
) => {
  const { detail } = widgetGfppClickedEvent;

  flowAPI.bi?.report(
    appSettingsClickOnSettingsButton({
      app_id: await editorSDK.info.getAppDefinitionId(TOKEN),
      msid: await editorSDK.info.getMetaSiteId(TOKEN),
      element_type: await getCustomElementTagName(
        editorSDK,
        detail.componentRef,
        webComponents
      ),
    })
  );
};

const getEditorSdkSource = (editorSDK: FlowEditorSDK) =>
  editorSDK.info.getSdkVersion(TOKEN).scriptSrc;

interface SaveSettingsToTPService {
  url: string;
  settings: object;
  flowApi: FlowAPI;
  appId: string;
}

export const saveSettingsToThirdPartyService = async ({
  url,
  settings,
  flowApi,
  appId,
}: SaveSettingsToTPService): Promise<void> => {
  if (!url) {
    return;
  }

  try {
    await flowApi.httpClient.post(
      'https://editor.wix.com/_serverless/web-component-wrapper-signer/send-data',
      {
        url,
        settingsData: settings,
        appId,
      }
    );
  } catch (error) {
    // probably have a proper error handling
    console.error(`Failed to send settings to ${url}`);
    return;
  }
};

export const getCustomElementTagName = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  webComponents: IWebComponent[]
) => {
  const { controllerType: componentId }: any =
    await editorSDK.components.data.get(TOKEN, {
      componentRef,
    });
  const relevantComponent = webComponents.find(
    (webComp) => webComp.componentId === componentId
  );

  return relevantComponent?.data?.tagName;
};
