import { DeviceType } from '@wix/platform-editor-sdk';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  APESTER_APP_ID,
  TOLSTOY_APP_ID,
} from '../../app-specific-logic/app-ids';
import { TOKEN } from '../../consts';

// TODO: handles view mode change and theme change
export const updateViewMode = async ({
  editorSDK,
  compRef,
  mode,
  appDefinitionId,
  // TODO: Reset is only true when theme is changed
  reset = false,
}: {
  mode?: DeviceType;
  compRef: ComponentRef;
  editorSDK: FlowEditorSDK;
  appDefinitionId: string;
  reset?: boolean;
}) => {
  const connections = await editorSDK.document.controllers.listConnections(
    TOKEN,
    { componentRef: compRef }
  );

  // getting the connected component from controller
  const controllerRef: any = connections[0]?.controllerRef;

  // defend when we don't have connected component
  // TODO: looks like the update in settings-panel
  // not working as it should and we here update it again check why
  if (controllerRef) {
    // getting new data from component after changing it in settings panel
    const { config } = await editorSDK.document.controllers.getData(TOKEN, {
      controllerRef,
    });

    // getting old data from component
    const componentData = await editorSDK.components.data.get(TOKEN, {
      componentRef: compRef,
    });

    const currentInitialAttributes = JSON.parse(
      // @ts-expect-error
      componentData.initialAttributes
    );
    const currentWixConfig = JSON.parse(currentInitialAttributes.wixconfig);
    // make sure that only apster and tolstoy has data as attribute.
    if (![APESTER_APP_ID, TOLSTOY_APP_ID].includes(appDefinitionId)) {
      currentWixConfig.data = null;
    }
    const newWidgetData = currentWixConfig.data
      ? {
          ...currentWixConfig,
          data: {
            ...currentWixConfig.data,
            ...config,
          },
          formFactor: mode ?? currentWixConfig.formFactor,
          deviceType: mode ?? currentWixConfig.deviceType,
        }
      : {
          ...currentWixConfig,
          formFactor: mode ?? currentWixConfig.formFactor,
          deviceType: mode ?? currentWixConfig.deviceType,
        };

    const currentWixSettings = JSON.parse(
      currentInitialAttributes?.wixsettings || '{}'
    );

    let newSettingsData = {
      ...currentWixSettings,
      ...config,
    };

    if (reset) {
      newSettingsData = { componentId: newSettingsData.componentId };
      editorSDK.controllers.saveConfiguration(TOKEN, {
        controllerRef,
        config: newSettingsData,
      });
    }

    const mergedConfig = newSettingsData;
    const attributes = {
      wixconfig: JSON.stringify(newWidgetData),
      wixsettings: JSON.stringify({ ...mergedConfig }),
      ts: new Date().getTime(),
    };
    if (JSON.stringify(attributes).length > 1023) {
      attributes.wixsettings = JSON.stringify({
        message:
          'Seems that you reached the data limitation. plesae set "Data Endpoint URL" as an option to save the data to external DB',
      });
    }
    // @ts-expect-error
    componentData.initialAttributes = JSON.stringify(attributes);

    // update the new data in component
    editorSDK.components.data.update(TOKEN, {
      componentRef: compRef,
      data: componentData,
    });
  }
};
