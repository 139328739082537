import { InitialAppData } from '@wix/platform-editor-sdk';
import { AppManifestBuilder, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { openWidgetSelectorModal } from '../../lib/installation-flows/install-editor-actions';
import { HotelsDashboardPage } from './hotels.constants';
import {
  openHotelsDashboardPage,
  openHotelsPagesPanel,
} from './hotels.helpers';

export function addMyBusinessPanelConfigurationToHotels({
  appManifestBuilder,
  editorSDK,
  initialAppData,
}: {
  appManifestBuilder: AppManifestBuilder;
  editorSDK: FlowEditorSDK;
  initialAppData: InitialAppData;
}) {
  const { instance } = initialAppData;

  appManifestBuilder.configureManagementActions((managementActionsBuilder) => {
    managementActionsBuilder.mainActions().addAction(
      {
        title: 'Manage Rooms',
        icon: 'appManager_settingsAction',
        onClick: (event) => {
          openHotelsDashboardPage({
            editorSDK,
            pageName: HotelsDashboardPage.RoomTypes,
            instance,
            referralInfo: 'app_manager',
          });
        },
      },
      {
        title: 'Add a Room Rate',
        icon: 'appManager_addElementsAction',
        onClick: (event) => {
          openHotelsDashboardPage({
            editorSDK,
            pageName: HotelsDashboardPage.RoomRates,
            instance,
            referralInfo: 'app_manager',
          });
        },
      }
    );

    managementActionsBuilder.customActions().addAction(
      {
        title: 'Manage Extras',
        icon: 'appManager_settingsAction',
        type: 'dashboard',
        onClick: (event) => {
          openHotelsDashboardPage({
            editorSDK,
            pageName: HotelsDashboardPage.Extras,
            instance,
            referralInfo: 'app_manager',
          });
        },
      },
      {
        title: 'Manage Pages',
        icon: 'appManager_pagesAction',
        type: 'editorActions',
        onClick: (event) => {
          openHotelsPagesPanel({ editorSDK });
        },
      },
      {
        title: 'Add Hotels Elements',
        icon: 'appManager_addElementsAction',
        type: 'editorActions',
        onClick: (event) => {
          openWidgetSelectorModal({ editorSDK });
        },
      }
    );

    managementActionsBuilder.upgradeAction().set({
      upgradeType: 'SITE_UPGRADE',
      upgradeText: 'To accept payments and orders,',
      upgradeLinkText: 'upgrade now.',
    });

    managementActionsBuilder.learnMoreAction().set({
      id: 'f3e74f18-30a3-4cfc-82c9-9838b941efd9',
    });
  });
}
