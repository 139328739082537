import { HttpClient } from '@wix/http-client';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { httpClient, initHttpClient } from './httpClient';
import { managedApps } from '@wix/ambassador-devcenter-marketplace-v1-managed-web-solution/http';

initHttpClient(new HttpClient({ baseURL: '' }));

const GENERAL_APPLICATION_ID = '22bef345-3c5b-4c18-b782-74d4085112ff';

export async function isEligibleForUpgrade(
  editorSDK: FlowEditorSDK | null,
  applicationId: string
): Promise<boolean> {
  if (!editorSDK) {
    return false;
  }
  const generalApp = await editorSDK.document.tpa.app.getDataByAppDefId(
    GENERAL_APPLICATION_ID,
    GENERAL_APPLICATION_ID
  );
  const generalInstance = generalApp.instance;
  try {
    const { data } = await httpClient.request(managedApps({}), {
      signedInstance: generalInstance,
    });
    const managedWebSolutions = data?.managedWebSolutions ?? [];
    return managedWebSolutions.some(
      (item) =>
        item?.webSolutionBase?.id === applicationId && item.eligibleForUpgrade
    );
  } catch (error) {
    console.error('Error fetch managed apps');
    return false;
  }
}
