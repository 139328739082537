import {
  WebComponentData,
  ScriptType,
} from '@wix/ambassador-app-service-webapp/types';
import { generateURL } from '../lifecycle-flows/update-component';
import { customElementComponentType } from '../../consts';

export const getCustomElementStructure = ({
  applicationId,
  data,
  instanceId,
  metaSiteDataAndDefault = {},
  isPage = false,
  formFactor = 'Desktop',
  widgetId = '',
}: {
  applicationId: string;
  data: WebComponentData;
  instanceId: string;
  isPage?: boolean;
  metaSiteDataAndDefault?: any;
  formFactor?: string;
  widgetId?: string;
}) => {
  let mergedConfig = metaSiteDataAndDefault;
  if (JSON.stringify(mergedConfig).length > 1023) {
    mergedConfig = {
      message:
        'Seems that you reached the data limitation. plesae set "Data Endpoint URL" as an option to save the data to external DB',
    };
  }
  const initialAttributes = JSON.stringify({
    wixconfig: JSON.stringify({
      instanceId,
      viewMode: 'Editor',
      deviceType: formFactor,
      data: {},
    }),
    wixsettings: JSON.stringify(mergedConfig),
  });

  return {
    componentType: 'platform.components.AppWidget',
    type: 'Container',
    data: {
      applicationId,
      id: 'custom_element_id',
      controllerType: widgetId,
      type: 'AppController',
      name: '',
    },
    style: 'appWidget1',
    layout: {
      x: isPage ? 0 : data?.size?.width ? 490 - data?.size?.width / 2 : 400,
      y: isPage ? 0 : 64,
      width: isPage ? 980 : data?.size?.width,
      height: isPage ? 980 : data?.size?.height,
    },
    components: [
      {
        componentType: customElementComponentType,
        data: {
          url: generateURL(data.scriptTag, instanceId),
          scriptType:
            data?.scriptType === ScriptType.MODULE ? 'module' : undefined,
          tagName: data.tagName,
          type: 'CustomElement',
          role: 'webComponent',
          initialAttributes,
        },
        layout: {
          x: 0,
          y: 0,
          width: '100%',
          height: '100%',
        },
        mobileStructure: {
          layout: {
            height: data?.defaultMobileHeight,
            width: 320,
          },
        },
        style: {},
        config: {},
      },
    ],
  };
};
