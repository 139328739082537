import { WebComponentData } from '@wix/ambassador-app-service-webapp/types';
import { ColorPalete, PageRef } from '@wix/platform-editor-sdk';
import { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getCustomElementStructure } from './get-custom-element-structure';
import { IWebComponent, TFont } from '../../types/web-component';
import { checkPageSlug } from '../../utils/slug-check';
import { saveSettingsToThirdPartyService } from '../panel-flows/settings';
import {
  filterPage,
  findComponentsByType,
  findPagesThatHaveNotBeenInstalledInThePast,
} from './install.utils';
import { TOKEN, customElementComponentType } from '../../consts';

export const openWidgetSelectorModal = async ({
  editorSDK,
}: {
  editorSDK: FlowEditorSDK;
}) => {
  const metaSiteId = await editorSDK.document.info.getMetaSiteId(TOKEN);
  const editorSDKVersion = editorSDK.info.getSdkVersion(TOKEN);

  editorSDK.editor.openModalPanel(TOKEN, {
    url: `https://www.wix.com/_serverless/dynamic-settings-renderer?wix-sdk-version=${editorSDKVersion.scriptSrc}&openWidgetsModal=true&componentId=not_exists&metaSiteId=${metaSiteId}`,
    shouldHideHeader: true,
    width: 680,
    height: 423,
  });
};

export const createNewPage = async ({
  componentDefinition,
  editorSDK,
  appDefinitionId,
}: {
  componentDefinition: IWebComponent;
  editorSDK: FlowEditorSDK;
  appDefinitionId: string;
}) => {
  const {
    showPageInMenu = false,
    tagName,
    slug,
    modalTitle,
  } = componentDefinition.data as WebComponentData;
  const pages = await editorSDK.pages.data.getAll('');

  const validPageSlug = checkPageSlug(
    slug as string,
    pages.map((page) => page.pageUriSEO)
  );
  return editorSDK.pages.add(TOKEN, {
    title: modalTitle ?? (tagName as string),
    shouldAddMenuItem: showPageInMenu,
    shouldNavigateToPage: true,
    definition: {
      type: 'Page',
      componentType: 'mobile.core.components.Page',
      data: {
        tpaPageId: `${tagName}${
          componentDefinition.componentId
            ? `|${componentDefinition.componentId}`
            : ``
        }`,
        managingAppDefId: appDefinitionId,
        pageUriSEO: validPageSlug || tagName,
        metaData: {
          pageId: tagName as string,
        },
      },
    },
  });
};

export async function addComponent({
  editorSDK,
  fullWidth = false,
  componentDefinition,
  pageRef,
}: {
  editorSDK: FlowEditorSDK;
  componentDefinition: any;
  fullWidth?: boolean;
  pageRef: PageRef;
}) {
  const componentRef = await editorSDK.document.components.add(TOKEN, {
    componentDefinition,
    pageRef,
  });
  return componentRef;
}

export const addComponentToPage = async ({
  editorSDK,
  componentDefinition,
  instanceId,
  applicationId,
  metaSiteData,
  fullWidth = false,
  isPage = false,
  pageRef,
  flowApi,
}: {
  flowApi: FlowAPI;
  editorSDK: FlowEditorSDK;
  instanceId: string;
  applicationId: string;
  metaSiteData: Record<string, any>;
  componentDefinition: IWebComponent;
  fullWidth?: boolean;
  isPage?: boolean;
  pageRef: PageRef;
}) => {
  const formFactor = await editorSDK.editor.info.getEditorMode(TOKEN);
  const fonts: Record<string, TFont> =
    await editorSDK.document.theme.fonts.getMap(TOKEN);
  const colors: ColorPalete = await editorSDK.document.theme.colors.getAll(
    TOKEN
  );

  const customElementComponentRef = await addComponent({
    editorSDK,
    componentDefinition: getCustomElementStructure({
      applicationId,
      data: componentDefinition.data ?? {},
      instanceId,
      metaSiteDataAndDefault: {
        ...metaSiteData,
      },
      formFactor,
      widgetId: componentDefinition.componentId,
      isPage,
    }),
    fullWidth,
    pageRef,
  });

  const currentElementRef = await findComponentsByType(
    editorSDK,
    customElementComponentRef,
    customElementComponentType
  );

  await editorSDK.document.controllers.saveConfiguration(TOKEN, {
    controllerRef: customElementComponentRef,
    config: { componentId: componentDefinition.componentId, ...metaSiteData },
  });

  await editorSDK.document.controllers.connect(TOKEN, {
    controllerRef: customElementComponentRef,
    connectToRef: currentElementRef[0],
    role: 'webComponent',
    connectionConfig: { time: new Date().getTime() },
  });
  // if fetchInitialData is set we will send there initial data + theme
  componentDefinition.data?.gfppSettings?.fetchInitialData &&
    saveSettingsToThirdPartyService({
      appId: applicationId,
      flowApi,
      url: componentDefinition.data?.gfppSettings?.fetchInitialData,
      settings: {
        data: {},
        componentId: componentDefinition.componentId,
        instanceId,
        theme: {
          colors,
          fonts,
        },
      },
    });
};

export async function install(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  metaSiteData: any = {},
  flowApi: FlowAPI
) {
  const applicationId = await editorSDK.info.getAppDefinitionId(TOKEN);
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
  } = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId
  );
  // @ts-expect-error
  const webComponents: IWebComponent[] = appData.components.filter(
    (comp: { type: string }) => comp.type === 'WEB'
  );
  const webComponentsPages = filterPage(webComponents as IWebComponent[]);

  const pagesToAdd = await findPagesThatHaveNotBeenInstalledInThePast(
    webComponentsPages as IWebComponent[],
    editorSDK
  );

  for (const componentDefinition of pagesToAdd) {
    const pageRef = await createNewPage({
      componentDefinition,
      editorSDK,
      appDefinitionId,
    });

    await addComponentToPage({
      flowApi,
      pageRef,
      componentDefinition,
      editorSDK,
      instanceId: appData.instanceId,
      applicationId,
      metaSiteData,
      fullWidth: true,
      isPage: true,
    });
  }

  pagesToAdd.length > 1 &&
    (await editorSDK.pages.navigateTo(TOKEN, {
      pageRef: { id: pagesToAdd[0].componentId as string },
    }));
}
