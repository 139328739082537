import { MainActionDefinition } from '@wix/platform-editor-sdk';
import { AppManifestBuilder, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  HOTELS_BOOKING_PAGES,
  HOTEL_RUNNER_APP_ID,
} from '../../app-specific-logic/hotels/hotels.constants';
import { hasExternalDashboardUrl } from '../../lib/panel-flows/dashboard';
import { IWebComponent } from '../../types/web-component';
import { isEligibleForUpgrade } from '../../utils/is-eligable-for-upgrade.utils';
import componentJson from './.component.json';

export async function configureWidgetManifest(
  appManifestBuilder: AppManifestBuilder,
  editorSDK: FlowEditorSDK,
  appDefinitionId: string
) {
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  } = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId
  );

  const webComponent: IWebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string }) => comp.type === 'WEB'
      )) ||
    {};

  const webComponents: IWebComponent[] =
    appData?.components?.filter((comp: any) => comp?.type === 'WEB') ?? [];

  const { connectLabel } = webComponent.data || {};

  const hasExternal = hasExternalDashboardUrl(appData);

  const isUpgrade: boolean = await isEligibleForUpgrade(
    editorSDK,
    appDefinitionId
  );

  // TODO : leaving this for now until we check the behavior for HotelRunner Staging and Apester
  appManifestBuilder.configureWidget(componentJson.id, (widgetBuilder) => {
    widgetBuilder.set({
      nickname: componentJson.id,
      displayName: componentJson.id,
    });
    widgetBuilder
      .gfpp()
      .set('mainAction1', { actionId: 'connect', label: 'Settings' })
      .set('mainAction2', { behavior: 'HIDE' })
      .set('help', {
        id: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
      })
      .set('animation', { behavior: 'HIDE' });
  });

  webComponents.forEach((comp) => {
    const { componentId } = comp;
    const dashboardButtonBehavior: MainActionDefinition | any = hasExternal
      ? {
          behavior: 'HIDE',
        }
      : {
          actionId: 'dashboard',
          label: 'Manage',
        };

    const upgradeButtonBehavior =
      isUpgrade && appData.appDefinitionId !== HOTEL_RUNNER_APP_ID
        ? 'DEFAULT'
        : 'HIDE';

    appManifestBuilder.configureWidget(
      componentId ?? 'webComponent',
      (widgetBuilder) => {
        widgetBuilder.set({
          displayName: comp.data?.widget?.name ?? componentId,
          nickname: componentId,
        });
        // TODO: remove this when we get widget behavior from csm
        // specific case for hotel runner bookings page
        componentId &&
          HOTELS_BOOKING_PAGES.includes(componentId) &&
          widgetBuilder
            .behavior()
            .set({ duplicatable: false, removable: false });
        widgetBuilder
          .gfpp()
          .set('mainAction1', {
            actionId: 'connect',
            label: connectLabel || 'Settings',
          })
          .set('mainAction2', dashboardButtonBehavior)
          .set('help', {
            id: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
          })
          .set('upgrade', {
            behavior: upgradeButtonBehavior,
          })
          .set('animation', { behavior: 'HIDE' })
          // TODO : 'connect' is a default name for the connect to data GFPP. i think we should change the name of of settings id to 'settings' to be less confusing
          .set('connect', { behavior: 'HIDE' });
      }
    );
  });
}
